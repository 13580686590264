// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "B_t3 d_bD";
export var storyRowWrapper = "B_hx d_hx d_bK";
export var storyLeftWrapper = "B_t4 d_bz d_bP";
export var storyWrapperFull = "B_t5 d_cD";
export var storyWrapperFullLeft = "B_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "B_mS d_hy";
export var storyLeftWrapperCenter = "B_t6 d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "B_t7 d_hF";
export var storyHeader = "B_t8 d_hD d_w d_cs";
export var storyHeaderCenter = "B_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "B_hB d_hB d_by d_dw";
export var storyBtnWrapper = "B_t9 d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "B_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "B_sb d_fg d_Z";
export var imageWrapperFull = "B_sc d_w d_H d_bf d_Z";
export var SubtitleSmall = "B_qv G_qv G_vp G_vB";
export var SubtitleNormal = "B_qw G_qw G_vp G_vC";
export var SubtitleLarge = "B_qx G_qx G_vp G_vD";
export var textLeft = "B_dv";
export var textCenter = "B_dw";
export var textRight = "B_dx";