// extracted by mini-css-extract-plugin
export var carouselContainer = "K_wQ d_w d_H d_bf d_Z";
export var carouselContainerCards = "K_wR K_wQ d_w d_H d_bf d_Z";
export var carouselContainerSides = "K_wS d_w d_H d_Z";
export var prevCarouselItem = "K_wT d_w d_H d_0 d_k";
export var prevCarouselItemL = "K_wV K_wT d_w d_H d_0 d_k";
export var moveInFromLeft = "K_wW";
export var prevCarouselItemR = "K_wX K_wT d_w d_H d_0 d_k";
export var moveInFromRight = "K_wY";
export var selectedCarouselItem = "K_wZ d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "K_w0 K_wZ d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "K_w1 K_wZ d_w d_H d_Z d_bf";
export var nextCarouselItem = "K_w2 d_w d_H d_0 d_k";
export var nextCarouselItemL = "K_w3 K_w2 d_w d_H d_0 d_k";
export var nextCarouselItemR = "K_w4 K_w2 d_w d_H d_0 d_k";
export var arrowContainer = "K_w5 d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "K_w6 K_w5 d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "K_w7 K_w6 K_w5 d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "K_w8 K_w5 d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "K_w9";
export var nextArrowContainerHidden = "K_xb K_w8 K_w5 d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "K_kG d_0";
export var prevArrow = "K_xc K_kG d_0";
export var nextArrow = "K_xd K_kG d_0";
export var carouselIndicatorContainer = "K_xf d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "K_xg d_w d_bz d_bF";
export var carouselText = "K_xh d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "K_xj K_xh d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "K_xk d_b7";
export var carouselIndicator = "K_xl K_xk d_b7";
export var carouselIndicatorSelected = "K_xm K_xk d_b7";
export var arrowsContainerTopRight = "K_xn d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "K_xp d_0 d_bl d_bC";
export var arrowsContainerSides = "K_xq d_0 d_bl d_bC";
export var smallArrowsBase = "K_xr d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "K_xs K_xr d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "K_xt K_xs K_xr d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "K_xv K_xr d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "K_xw K_xv K_xr d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "K_qr";
export var iconSmall = "K_xx";
export var multipleWrapper = "K_xy d_bC d_bF d_bf";
export var multipleImage = "K_xz d_bC";
export var sidesPrevContainer = "K_xB K_xs K_xr d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "K_xC K_xs K_xr d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";