// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "w_s8 d_gv d_cs";
export var heroHeaderCenter = "w_gw d_gw d_cs d_dw";
export var heroHeaderRight = "w_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "w_s9 d_gr d_cw";
export var heroParagraphCenter = "w_gs d_gs d_cw d_dw";
export var heroParagraphRight = "w_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "w_tb d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "w_tc d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "w_td d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "w_tf d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "w_tg d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "w_th G_th";
export var heroExceptionNormal = "w_tj G_tj";
export var heroExceptionLarge = "w_tk G_tk";
export var Title1Small = "w_tl G_tl G_vp G_vq";
export var Title1Normal = "w_tm G_tm G_vp G_vr";
export var Title1Large = "w_tn G_tn G_vp G_vs";
export var BodySmall = "w_tp G_tp G_vp G_vJ";
export var BodyNormal = "w_tq G_tq G_vp G_vK";
export var BodyLarge = "w_tr G_tr G_vp G_vL";