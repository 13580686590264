// extracted by mini-css-extract-plugin
export var alignDiscLeft = "v_sJ d_fp d_bG d_dv";
export var alignLeft = "v_pB d_fp d_bG d_dv";
export var alignDiscCenter = "v_sK d_fq d_bD d_dw";
export var alignCenter = "v_bP d_fq d_bD d_dw";
export var alignDiscRight = "v_sL d_fr d_bH d_dx";
export var alignRight = "v_pC d_fr d_bH d_dx";
export var footerContainer = "v_sM d_dW d_bW";
export var footerContainerFull = "v_sN d_dT d_bW";
export var footerHeader = "v_kf d_kf";
export var footerTextWrapper = "v_sP d_w";
export var footerDisclaimerWrapper = "v_km d_km d_ck";
export var badgeWrapper = "v_sQ d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "v_sR d_bz d_bJ d_bN d_bL";
export var wide = "v_sS d_cy";
export var right = "v_sT d_bK";
export var line = "v_fk d_fl d_cv";
export var badgeDisclaimer = "v_sV d_bC d_bP d_bJ";
export var badgeContainer = "v_sW d_bz d_bH d_bP";
export var badge = "v_sX";
export var padding = "v_sY d_c7";
export var end = "v_sZ d_bH";
export var linkWrapper = "v_s0 d_dB";
export var link = "v_mZ d_dB";
export var colWrapper = "v_s1 d_cx";
export var consent = "v_f d_f d_bC d_bP";
export var disclaimer = "v_s2 d_bz d_bJ";
export var media = "v_s3 d_bx d_dy";
export var itemRight = "v_s4";
export var itemLeft = "v_s5";
export var itemCenter = "v_s6";
export var exceptionWeight = "v_s7 G_vR";