// extracted by mini-css-extract-plugin
export var socialContainer = "z_tQ";
export var instagramLink = "z_tR";
export var socialWrapperLeft = "z_nX d_dC d_Z d_bz d_bJ d_bp d_bG";
export var socialContentContainer = "z_tS";
export var socialContentContainerFull = "z_tT";
export var instagramContainer = "z_tV";
export var twitterContainer = "z_tW";
export var facebookContainer = "z_tX";
export var socialErrorContainer = "z_tY";
export var facebookContainerWide = "z_tZ";
export var twitterContainerWide = "z_t0";
export var socialParagraphCenter = "z_t1";
export var instaWrapper = "z_t2";
export var SubtitleSmall = "z_qv G_qv G_vp G_vB";
export var SubtitleNormal = "z_qw G_qw G_vp G_vC";
export var SubtitleLarge = "z_qx G_qx G_vp G_vD";
export var textLeft = "z_dv";
export var textCenter = "z_dw";
export var textRight = "z_dx";